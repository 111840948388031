import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as api from '@/services/impressment'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import moment from 'moment'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		impressmentList: [],
		actualImpressment: {},
		filters: {
			id: '',
			status: '0',
			customer_id: '',
			default_date: [moment().startOf('month'), moment().endOf('month')],
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			api
				.get(payload)
				.then((response) => {
					commit('SET_STATE', {
						impressmentList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar los registros. Intenta de nuevo.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})
			return new Promise((resolve, reject) => {
				api
					.getOne(id)
					.then((response) => {
						commit('SET_STATE', {
							actualImpressment: _.cloneDeep(response.data),
						})
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al consultar los registros. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.create(payload)
					.then(async (response) => {
						await dispatch('GET_ONE', response.data.id)
						router.replace(`/impressment/${response.data.id}`)
						resolve(response.data)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.update(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE_STATUS({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.updateStatus(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async DELETE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				api
					.deleteRecord(id)
					.then((response) => {
						Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(response, 'message', 'Registro eliminado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							resolve(response)
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al eliminar el registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CONFIRM({ commit, dispatch }, { elements, fromPurchaseOrders }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			api
				.confirm(elements)
				.then((response) => {
					Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(response, 'message', 'Registro generado con éxito.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						if (fromPurchaseOrders) {
							router.replace(`/purchase-orders/${response.data.purchase_order_id}`)
						} else {
							dispatch('GET')
						}
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Cotizaciones',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro. Intenta de nuevo.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GENERATE_PDF({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo cotización',
			})
			return new Promise((resolve, reject) => {
				api
					.generatePDF(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async SEND_GENERATE_PDF({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Enviando cotización',
			})
			return new Promise((resolve, reject) => {
				api
					.sendQuotation(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPLOAD_FILES({ commit, dispatch }, formData) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})

			await api.uploadFile(formData)
		},
		async GET_LOGS({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})

			return new Promise((resolve, reject) => {
				api
					.getLogs(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Cotizaciones',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener información del registro. Intenta de nuevo.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		impressmentList: (state) => state.impressmentList,
		actualImpressment: (state) => state.actualImpressment,
		filters: (state) => state.filters,
	},
}
